const parallaxEffect = () => {
    const parallaxImages = document.querySelectorAll('.parallax__bg');

    const moveImage = (image, topOfImage) => {

        let positionY = Math.min(100, Math.max(0, ((1 - topOfImage / (window.innerHeight)) / 1.5) * 100));
        image.style.objectPosition = `50% ${positionY}%`;
    }
    // Observe each image
    parallaxImages.forEach((image, index) => {
        // let imagePosition = image.getBoundingCelientRect().top;
        // console.log(imagePosition, "body");

        const viewPortHeight = window.innerHeight;
        window.addEventListener('scroll', () => {
            const topOfImage = image.getBoundingClientRect().top;
            const bottomOfImage = topOfImage + image.offsetHeight;

            const isImageInViewport = () => {
                return (topOfImage < viewPortHeight && bottomOfImage > 0);
            };

            if (isImageInViewport()) {
                moveImage(image, topOfImage);
            }
        });
    });
};

export default parallaxEffect