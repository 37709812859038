import Alpine from 'alpinejs';
import {
    lazyLoad
} from 'unlazy'
import parallaxEffect from './components/parallax-effect';
// import SimpleParallax from "simple-parallax-js/vanilla";

window.Alpine = Alpine;


const existingModules = ['home', 'faqs', 'contact', 'our-services'];

async function loadPageModule(moduleName) {
    try {
        const module = await import(`./pages/${moduleName}.js`);
        console.log(`${moduleName} module loaded`);
        module.initAlpine();
        Alpine.start();
    } catch (err) {
        console.error(`Failed to load ${moduleName} module`, err);
    }
}

// .seeMore

const seeMore = () => {
    let seeMoreButtons = document.querySelectorAll('.seeMore');
    seeMoreButtons.forEach(button => {
        button.addEventListener('click', () => {
            // Find the parent element containing the list items
            const parent = button.closest('.teamList'); // Replace '.parent-class' with the actual parent selector
            if (parent) {
                // Toggle the 'hidden' class on each list item with class 'extra' within this parent
                parent.querySelectorAll('li.extra').forEach(item => {
                    item.classList.toggle('hidden');
                });


                button.textContent = button.textContent === 'See More' ? 'See Less' : 'See More';
            }
        });
    });
};

document.addEventListener('DOMContentLoaded', () => {
    const page = document.body.dataset.page;
    if (page && existingModules.find(module => module === page)) {
        loadPageModule(page);
    }
    lazyLoad('img[data-srcset]')
    parallaxEffect();
    seeMore();
});

// Scale will zoom the image in potentially causing blurriness, but make the parallax scroll look faster
// Compensate for this by using larger image transforms
// new SimpleParallax(document.querySelectorAll('.parallax__bg'), {
//     scale: 1.5
// });